import React, { useRef, useEffect, useState, Suspense } from "react";
import Webcam from "react-webcam";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import * as cam from "@mediapipe/camera_utils";
import html2canvas from "html2canvas";
import Header from "./Header";
import Footer from "./Footer";
import './App.css';
import emailjs from "emailjs-com";

const App = () => {

  //Disable Right Click on Browser
  document.oncontextmenu = document.body.oncontextmenu = function () { return false; }


  // Refs for webcam and canvas elements
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // State variables
  const [imageURL, setimageURL] = useState("");
  const [load, setLoad] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);
  const [snapshotURL, setSnapshotURL] = useState("");
  const [userEmail, setUserEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [image, setImage] = useState(new Image());
  useEffect(() => {
    // Preload or fetch landscape images
    landscapeBackgrounds.forEach((bg) => {
      const img = new Image();
      img.src = bg.src;
    });
  
    // Preload or fetch portrait images
    portraitBackgrounds.forEach((bg) => {
      const img = new Image();
      img.src = bg.src;
    });
  }, []);
// Display the Segmented video on top of background
const onResults = async (results) => {
  const img = document.getElementById('vbackground');
  const imgWidth = img.width;
  const imgHeight = img.height;
  const videoWidth = webcamRef.current.video.videoWidth;
  const videoHeight = webcamRef.current.video.videoHeight;
  canvasRef.current.width = videoWidth;
  canvasRef.current.height = videoHeight;
  const canvasElement = canvasRef.current;
  const canvasCtx = canvasElement.getContext('2d');
  canvasCtx.save();

  // Calculate the cropping percentages based on viewport width
  const viewportWidth = window.innerWidth;
  let leftCropPercentage = 0.025; // 2.5%
  let rightCropPercentage = 0.025; // 2.5%
  let topCropPercentage = 0.04; // 4%
  let bottomCropPercentage = 0.04; // 4%

  if (viewportWidth <= 800) {
    leftCropPercentage = 0.03; // 2%
    rightCropPercentage = 0.03; // 2%
    topCropPercentage = 0.018; // 45%
    bottomCropPercentage = 0.018; // 45%
  }

  // Calculate the position to center the segmented image on the canvas
  const cropWidth = results.image.width * (1 - leftCropPercentage - rightCropPercentage);
  const cropHeight = results.image.height * (1 - topCropPercentage - bottomCropPercentage);
  const cropX = (results.image.width - cropWidth) / 2;
  const cropY = (results.image.height - cropHeight) / 2;
  const centerX = (canvasElement.width - cropWidth) / 2;
  const centerY = (canvasElement.height - cropHeight) / 2;

  // Clear the canvas
  canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

  // Draw the segmented image centered on the canvas with cropping
  canvasCtx.drawImage(
    results.image,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    centerX,
    centerY,
    cropWidth,
    cropHeight
  );

  // Only overwrite existing pixels.
  canvasCtx.globalCompositeOperation = 'destination-atop';
  canvasCtx.drawImage(
    results.segmentationMask,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    centerX,
    centerY,
    cropWidth,
    cropHeight
  );

  // Only overwrite missing pixels.
  canvasCtx.globalCompositeOperation = 'destination-over';
  canvasCtx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);

  canvasCtx.restore();
  setLoad(true);
};



  
const portraitBackgrounds = [
  { src: "../assets/portrait (1).jpg", alt: "Background 1" },
  { src: "../assets/portrait (2).jpg", alt: "Background 2" },
  { src: "../assets/portrait (3).jpg", alt: "Background 3" },
  { src: "../assets/portrait (4).jpg", alt: "Background 4" },
  { src: "../assets/portrait (5).jpg", alt: "Background 5" },
  { src: "../assets/portrait (6).jpg", alt: "Background 6" },
  { src: "../assets/portrait (7).jpg", alt: "Background 7" },
  { src: "../assets/portrait (8).jpg", alt: "Background 8" },
  { src: "../assets/portrait (9).jpg", alt: "Background 9" },
  { src: "../assets/portrait (10).jpg", alt: "Background 10" },
  { src: "../assets/portrait (11).jpg", alt: "Background 11" },
  { src: "../assets/portrait (12).jpg", alt: "Background 12" },
  { src: "../assets/portrait (13).jpg", alt: "Background 13" },
  { src: "../assets/portrait (14).jpg", alt: "Background 14" },
  { src: "../assets/portrait (15).jpg", alt: "Background 15" },
  { src: "../assets/portrait (16).jpg", alt: "Background 16" },
  { src: "../assets/portrait (17).jpg", alt: "Background 17" },
  { src: "../assets/portrait (18).jpg", alt: "Background 18" },
  { src: "../assets/portrait (19).jpg", alt: "Background 19" },
  { src: "../assets/portrait (20).jpg", alt: "Background 20" },
  { src: "../assets/portrait (21).jpg", alt: "Background 21" },
  { src: "../assets/portrait (22).jpg", alt: "Background 22" },
  { src: "../assets/portrait (23).jpg", alt: "Background 23" },
  { src: "../assets/portrait (24).jpg", alt: "Background 24" },
  { src: "../assets/portrait (25).jpg", alt: "Background 25" },
  { src: "../assets/portrait (26).jpg", alt: "Background 26" },
  { src: "../assets/portrait (27).jpg", alt: "Background 27" },
  { src: "../assets/portrait (28).jpg", alt: "Background 28" },
  { src: "../assets/portrait (29).jpg", alt: "Background 29" },
  { src: "../assets/portrait (30).jpg", alt: "Background 30" },
  { src: "../assets/portrait (31).jpg", alt: "Background 31" },
  // Add more background objects as needed
];
const landscapeBackgrounds = [
  { src: "../assets/landscape (1).jpg", alt: "Background 1" },
  { src: "../assets/landscape (2).jpg", alt: "Background 2" },
  { src: "../assets/landscape (3).jpg", alt: "Background 3" },
  { src: "../assets/landscape (4).jpg", alt: "Background 4" },
  { src: "../assets/landscape (5).jpg", alt: "Background 5" },
  { src: "../assets/landscape (6).jpg", alt: "Background 6" },
  { src: "../assets/landscape (7).jpg", alt: "Background 7" },
  { src: "../assets/landscape (8).jpg", alt: "Background 8" },
  { src: "../assets/landscape (9).jpg", alt: "Background 9" },
  { src: "../assets/landscape (10).jpg", alt: "Background 10" },
  { src: "../assets/landscape (11).jpg", alt: "Background 11" },
  { src: "../assets/landscape (12).jpg", alt: "Background 12" },
  { src: "../assets/landscape (13).jpg", alt: "Background 13" },
  { src: "../assets/landscape (14).jpg", alt: "Background 14" },
  { src: "../assets/landscape (15).jpg", alt: "Background 15" },
  { src: "../assets/landscape (16).jpg", alt: "Background 16" },
  { src: "../assets/landscape (17).jpg", alt: "Background 17" },
  { src: "../assets/landscape (18).jpg", alt: "Background 18" },
  { src: "../assets/landscape (19).jpg", alt: "Background 19" },
  { src: "../assets/landscape (20).jpg", alt: "Background 20" },
  { src: "../assets/landscape (21).jpg", alt: "Background 21" },
  { src: "../assets/landscape (22).jpg", alt: "Background 22" },
  { src: "../assets/landscape (23).jpg", alt: "Background 23" },
  { src: "../assets/landscape (24).jpg", alt: "Background 24" },
  { src: "../assets/landscape (25).jpg", alt: "Background 25" },
  { src: "../assets/landscape (26).jpg", alt: "Background 26" },
  { src: "../assets/landscape (27).jpg", alt: "Background 27" },
  { src: "../assets/landscape (28).jpg", alt: "Background 28" },
  { src: "../assets/landscape (29).jpg", alt: "Background 29" },
  { src: "../assets/landscape (30).jpg", alt: "Background 30" },
  { src: "../assets/landscape (31).jpg", alt: "Background 31" },
  // Add more background objects as needed
];

// Change Background of Webcam
const handleBackgroundClick = (src) => {
setimageURL(src);
closeBackgroundsModal();
console.log(imageURL)
};




  // Perform Segmentation on live webcam
  useEffect(() => {
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
      },
    });
    selfieSegmentation.setOptions({
      modelSelection: 1,
    });
    selfieSegmentation.onResults(onResults);

    if (webcamVisible && webcamRef.current) {
      const camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          await selfieSegmentation.send({ image: webcamRef.current.video });
        },
      });
      camera.start();
    }
    const img = new Image();
  img.src = imageURL;
  img.onload = () => {
    setImage(img);
  };
    console.log('UseEffect is called')
  }, [webcamVisible], [imageURL]);


  // Functions for opening and closing modals
  function openInstructionsModal() {
    let instructionsModal = document.getElementById('instructionsModal');
    instructionsModal.style.display = "flex";
  }

  function openBackgroundsModal() {
    let backgroundsModal = document.getElementById('backgroundsModalWrapper');
    backgroundsModal.style.display = "flex";
    setWebcamVisible(true);
  }

  const openOutputModal = async () => {
    let outputModal = document.getElementById('outputModalWrapper');
    outputModal.style.display = "block";
  }

  function closeInstructionsModal() {
    let instructionsModal = document.getElementById('instructionsModal');
    instructionsModal.style.display = "none";
  }

  function closeWebcamModal() {
    setWebcamVisible(false);
    let webcamModal = document.getElementById('webcamModalWrapper');
    webcamModal.style.display = "none";
    console.log("closeWebcamModal is called");
  }

  function closeOutputModal() {
    let outputModal = document.getElementById('outputModalWrapper');
    outputModal.style.display = "none";
  }

  function closeBackgroundsModal() {
    let backgroundsModal = document.getElementById('backgroundsModalWrapper');
    backgroundsModal.style.display = "none";
  }

  const captureSnapshot = async () => {
    try {
      const canvas = await html2canvas(document.getElementById("video"));
      const compressedDataURL = await compressImage(canvas.toDataURL(), 1500);
      setSnapshotURL(compressedDataURL);
      openOutputModal();
    } catch (error) {
      console.error("Error capturing and compressing snapshot:", error);
    }
  };

  // Function to compress the image
  const compressImage = async (dataURL, maxSizeInKB) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = dataURL;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const maxDimension = Math.sqrt(maxSizeInKB * 1024);
        let width = image.width;
        let height = image.height;

        if (width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        }, "image/jpeg", 1); // Adjust quality as needed (0.7 means 70% quality)
      };
    });
  }; 

  // Function to send the email
  function sendCanvasAsAttachment() {
    if (isValidEmail) {
      const base64 = snapshotURL;
      emailjs.send('service_csnhcxj', 'template_q0hj0i8', {
        content: base64,
        mail_to: userEmail,
      }, "VZtfjduMLO9pdcgwg")
        .then(() => {
          alert('Image was Successfully sent!');
          window.location.replace("");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    } else {
      alert("Please enter a valid email address.");
    }
  }

  // Function to validate email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  // Handle email input change
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setUserEmail(email);
    setIsValidEmail(validateEmail(email));
  }

  return (
    <>
      <Header />
      <main>
        <img className="heroDesktop" src="../assets/banner_desktop.jpg" />
        <img className="heroTablet" src="../assets/banner_tablet.jpg" />
        <img className="heroPhone" src="../assets/banner_phone.jpg" />
        <div className="overlay-wrapper">
          <p>Embark on an extraordinary adventure to Ladakh, a region that promises awe-inspiring landscapes and a rich cultural experience. Nestled in the northern part of India, Ladakh is renowned for its lofty altitudes, captivating high-altitude lakes, and the thrill of trekking through remote villages. Discover the charm of its unique blend of adventure and cultural richness. From the picturesque landscapes to the cultural treasures waiting to be explored, Ladakh is a must-visit destination for those seeking a truly unforgettable travel experience.</p>
          <button onClick={openInstructionsModal}>Click to Start</button>
        </div>
        <div className="modal-wrapper" id="instructionsModal">
          {/* Close button */}
          <a onClick={closeInstructionsModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></a>
          <div className="instructionsModalDiv1">
            <p>1. Choose Your Background</p>
            <p>2. Position Your Face</p>
            <p>3. Capture</p>
            <p>4. Retake / Print</p>
          </div>
          <div className="instructionsModalDiv2">
            <img src="../assets/Start.webp" onClick={openBackgroundsModal} alt="Start" />
            <p>Start</p>
          </div>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          {webcamVisible && (
            <div className="modal-wrapper" id="webcamModalWrapper">
              {/* Close button */}
              <a onClick={closeWebcamModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></a>
              <div className="input-wrapper">
                <div className="videoContainer">
                  <div className="videoContent">
                    <div className="video" id="video" >
                      <Webcam
                        ref={webcamRef}
                        style={{
                          display: "none",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      ></canvas>
                    </div>
                  </div>
                  <button onClick={captureSnapshot}>Capture Snapshot</button>
                </div>
                <div className="backgroundContainer">
                  <div className="backgrounds">
                    <img id="vbackground" src={imageURL} alt="The Screen" className="background" />
                  </div>
                  <button onClick={openBackgroundsModal}>Choose Background</button>
                </div>
              </div>
            </div>
          )}
        </Suspense>
        <div className="modal-wrapper" id="backgroundsModalWrapper">
          {/* Close button */}
          <a onClick={closeBackgroundsModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></a>
          {landscapeBackgrounds.map((bg, index) => (
        <img
          key={index}
          src={bg.src}
          alt={bg.alt}
          className="clickable-image landscape"
          onClick={() => handleBackgroundClick(bg.src)}
          // loading="lazy"
        />
      ))}
          {portraitBackgrounds.map((bg, index) => (
        <img
          key={index}
          src={bg.src}
          alt={bg.alt}
          className="clickable-image portrait"
          onClick={() => handleBackgroundClick(bg.src)}
          // loading="lazy"
        />
      ))}

        </div>
        <div className="modal-wrapper" id="outputModalWrapper">
          <div className="output-wrapper" id="output">
            <img src={snapshotURL} alt="Snapshot" />
            <input
                type="email"
                id="emailInput"
                className="inputDisplay"
                placeholder="Enter your email"
                required
                value={userEmail}
                onChange={handleEmailChange}
              /> 
            <div className="output-btns">
            <input id="download"
                type="submit"
                className="inputDisplay"
                onClick={sendCanvasAsAttachment}
                value="Download"
              />
              <button onClick={closeOutputModal}>Retake Snapshot</button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
export default App;
